<template>
  <v-form v-model="valid" ref="form" lazy-validation @submit="submit">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="4">
          <v-text-field
            type="password"
            v-model="password"
            :rules="passwordRules"
            label="Password"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="4">
          <v-btn :disabled="!valid" color="success" class="mr-4" type="submit">
            Connexion
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          {{ message }}
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
export default {
  name: "LoginForm",

  data: () => ({
    valid: false,
    message: "",
    password: "",
    passwordRules: [(v) => !!v || "Password is required"],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
  }),
  methods: {
    submit(event) {
      event.preventDefault();
      if (this.$refs.form.validate() === true) {
        this.$store
          .dispatch("login", { email: this.email, password: this.password })
          .then((response) => {
            if (response.error || response.errors) {
              this.message = "nom d'utilisateur ou mot de passe incorrect: ";
              if (response.errors) {
                const errors = response.errors.map((item) => item.msg);
                this.message += errors.join(",");
              } else if (response.error) {
                this.message += response.error;
              }
            }
          });
      }
    },
  },
};
</script>
