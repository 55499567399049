<template>
  <div class="login">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="4">
          <h1>Login</h1>
        </v-col>
      </v-row>
    </v-container>
    <login-form />
  </div>
</template>

<script>
import LoginForm from "../components/LoginForm.vue";

export default {
  name: "Login",

  components: {
    LoginForm,
  },
};
</script>
